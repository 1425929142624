import { DOCUMENT_STATUS } from 'components/consts/consts';
import { useMutation, useQueryClient } from 'react-query';
import { DealSummary } from 'types/types';
import { errorHandler } from '../utils/toasts';
import { generateDealSummaryQueryKey } from './queryKeys';

export const useDocumentUploadMutation = (
  uploadFileHandler: (documentInfo: {
    file: File | undefined;
    documentName: string;
  }) => Promise<void>,
  dealId: string,
  documentId?: string,
) => {
  const qc = useQueryClient();
  const mutationKey = generateDealSummaryQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    {
      file: File | undefined;
      documentName: string;
    },
    unknown
  >(uploadFileHandler, {
    mutationKey,
    onSuccess: (
      data: void,
      variables: { file: File | undefined; documentName: string },
    ) => {
      const { file, documentName } = variables;
      const fileName = file?.name;
      if (fileName) {
        qc.setQueryData(
          mutationKey,
          (oldDealSummary: DealSummary | undefined) => {
            if (!oldDealSummary)
              errorHandler(new Error('Deal summary invalid'));
            const documentObjectToOverwrite = oldDealSummary?.documents?.find(
              (docObj) => docObj.id === documentId,
            );

            if (documentObjectToOverwrite) {
              // Since js objects are references in an array we can
              // just modify the object and return oldDealSummary.documents
              documentObjectToOverwrite.properties = {
                ...documentObjectToOverwrite.properties,
                status: DOCUMENT_STATUS.PENDING_REVIEW,
                fileLastModifiedDate: new Date().toISOString(),
              };
            } else {
              const customDocumentObject = {
                id: 'optimisticId',
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                archived: false,
                properties: {
                  displayName: documentName,
                  status: DOCUMENT_STATUS.PENDING_REVIEW,
                  fileLastModifiedDate: new Date().toISOString(),
                },
              };
              oldDealSummary?.documents?.push(customDocumentObject);
            }

            return {
              legalCompanyName: oldDealSummary?.legalCompanyName,
              documents: oldDealSummary?.documents ?? [],
            };
          },
        );
      }
    },
    onError: (err) => {
      qc.invalidateQueries(mutationKey);
      console.error(err);
      errorHandler(
        new Error(
          'There was an error while uploading a file. Please try again.',
        ),
      );
    },
  });
};

export const useW9UploadMutation = (
  uploadFileHandler: (w9Info: {
    file: File | undefined;
    email: string;
  }) => Promise<void>,
) =>
  useMutation<
    void,
    unknown,
    {
      file: File | undefined;
      email: string;
    }
  >(uploadFileHandler, {
    onError: (err) => {
      console.error(err);
      errorHandler(
        new Error(
          'There was an error while uploading your W9. Please try again.',
        ),
      );
    },
  });
