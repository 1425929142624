import { ThemeOptions } from '@mui/material';

export const GLOBAL_THEME = {
  palette: {
    portalPalettes: {
      green: '#23A56E',
      darkgray: '#3D3D3D',
      lightgray: '#5A5A5A',
    },
  },
  typography: {
    fontFamily: 'Crique Grotesk',
  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: '0px 2px 8px #00000014',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#e0e4ec',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            backgroundColor: '#47BE97',
            color: 'white',
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: 'white',
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '16px',
        },
      },
    },
  },
} as ThemeOptions;

export const colors = {
  accentGreen: '#47BE97',
  darkBlueGray: '#333E5A',
  darkGray: '#5A5A5A',
  darkPurple: '#24205A',
  lightGray: '#DDDDDD',
  lightMint: '#F4FAF8',
  mediumGray: '#8A8A8A',
  mediumGreen: '#118252',
  mediumPurple: '#5F61AB',
  mint: '#23A56E',
  veryDarkGray: '#3D3D3D',
  white: '#f9f9f9',
  darkGreen: '#4C9980',
  lightGreen: '#C3DAD2',
  lightGray2: '#F5F5F5',
  lightGray3: '#7C7C7C',
  lightGray4: '#FBFBFB',
  lightGray5: '#9e9e9e',
  black: '#000000',
  lightBlueGray: '#DDE4EF',
};
