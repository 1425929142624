import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { colors } from 'themes';
import { ERCADocumentSummaryObject } from 'types/types';
import { formatDate } from '../utils/format';
import { UploadDocument } from './UploadDocument';
import { ERCA_DOCUMENT_STATUSES } from './consts/consts';
import { LANG } from './consts/lang';

const sharedContainerStyle = {
  padding: 2,
  boxSizing: 'border-box',
};

const statusColumnStyle = {
  maxWidth: '5vw',
  minWidth: '5vw',
  ...sharedContainerStyle,
};

const requiredDocumentsColumnStyle = {
  maxWidth: '35vw',
  minWidth: '35vw',
  ...sharedContainerStyle,
};

const fileUploadColumnStyle = {
  maxWidth: '50vw',
  minWidth: '50vw',
  ...sharedContainerStyle,
};

const getStatusColor = (status: string | undefined) => {
  switch (status) {
    case ERCA_DOCUMENT_STATUSES.UPLOAD_REQUIRED:
      return '#bb2801';
    case ERCA_DOCUMENT_STATUSES.PENDING_REVIEW:
      return '#e6bf2a';
    case ERCA_DOCUMENT_STATUSES.CONFIRMED:
      return '#008001';
    default:
      return '#bb2801';
  }
};

const canUpload = (status: string | undefined) =>
  !status || status === '' || status === ERCA_DOCUMENT_STATUSES.UPLOAD_REQUIRED;

interface IProps {
  title: string;
  subHeader: string | JSX.Element;
  documents: ERCADocumentSummaryObject[];
}

export const ERCADocumentTable: FunctionComponent<IProps> = ({
  title,
  subHeader,
  documents,
}) => (
  <>
    <Box>
      <Typography
        sx={{ fontSize: '24px', fontWeight: '700', marginBottom: '8px' }}
      >
        {title}
      </Typography>
      {subHeader && (
        <Typography sx={{ color: colors.darkGray, marginBottom: '16px' }}>
          {subHeader}
        </Typography>
      )}
    </Box>
    <TableContainer sx={{ marginBottom: '64px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={statusColumnStyle}>
              {LANG.label.documentTable.status}
            </TableCell>
            <TableCell sx={requiredDocumentsColumnStyle}>
              {LANG.label.documentTable.displayName}
            </TableCell>
            <TableCell sx={fileUploadColumnStyle}>
              {LANG.label.documentTable.fileDate}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents &&
            documents.map((document) => (
              <TableRow key={document.documentId}>
                <TableCell>
                  <Box
                    sx={{
                      display: 'inline-block',
                      width: '12px',
                      height: '12px',
                      backgroundColor: getStatusColor(document.status),
                      borderRadius: '50%',
                      margin: '0 16px',
                    }}
                  />
                </TableCell>
                <TableCell>
                  {document.displayName ? (
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                      <Typography>{document.displayName}</Typography>
                    </Box>
                  ) : (
                    LANG.error.displayName
                  )}
                </TableCell>
                <TableCell>
                  {canUpload(document.status) ? (
                    <UploadDocument
                      displayName={document.displayName}
                      documentId={document.documentId}
                      alternativeDocumentType="erca"
                    />
                  ) : (
                    <Typography>
                      {document.fileLastModifiedDate &&
                        formatDate(document.fileLastModifiedDate)}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
);
