export const LANG = {
  button: {
    submit: 'Submit',
  },
  description: {
    documentTable: {
      default:
        'Please allow 3-5 business days for document submission status updates',
      customTable:
        "Don't see the form you're looking for? Use this custom document form!",
    },
  },
  error: {
    displayName: 'No Display Name Available',
  },
  label: {
    documentName: 'Name of Document:',
    documentTable: {
      displayName: 'Required Documents',
      fileDate: 'Date Submitted',
      status: 'Status',
    },
    checkUpload: {
      checkUpload: 'Image of Check',
      periodDate: 'Tax Period Date',
      amount: 'Check Amount',
    },
  },
};
