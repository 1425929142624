import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { colors } from 'themes';
import { DocumentSummaryObject } from 'types/types';
import { UploadDocument } from './UploadDocument';
import { LANG } from './consts/lang';
import { formatDate } from '../utils/format';
import { DOCUMENT_STATUS } from './consts/consts';

const sharedContainerStyle = {
  padding: 2,
  boxSizing: 'border-box',
};

const statusColumnStyle = {
  maxWidth: '5vw',
  minWidth: '5vw',
  ...sharedContainerStyle,
};

const requiredDocumentsColumnStyle = {
  maxWidth: '35vw',
  minWidth: '35vw',
  ...sharedContainerStyle,
};

const fileUploadColumnStyle = {
  maxWidth: '50vw',
  minWidth: '50vw',
  ...sharedContainerStyle,
};

const getStatusColor = (status?: string) => {
  switch (status) {
    case DOCUMENT_STATUS.NOT_YET_RECIEVED:
      return '#bb2801';
    case DOCUMENT_STATUS.PENDING_REVIEW:
      return '#e6bf2a';
    case DOCUMENT_STATUS.IQ_CONFIRMED:
    case DOCUMENT_STATUS.IR_CONFIRMED:
    case DOCUMENT_STATUS.IQ_QC_CONFIRMED:
    case DOCUMENT_STATUS.IR_QC_CONFIRMED:
      return '#008001';
    default:
      return '#bb2801';
  }
};

const canUpload = (status: string) =>
  status === DOCUMENT_STATUS.NOT_YET_RECIEVED;

interface IProps {
  title: string;
  subHeader: string | JSX.Element;
  documents: DocumentSummaryObject[] | undefined;
}

export const DocumentTable: FunctionComponent<IProps> = ({
  title,
  subHeader,
  documents,
}) => (
  <>
    <Box>
      <Typography
        sx={{ fontSize: '24px', fontWeight: '700', marginBottom: '8px' }}
      >
        {title}
      </Typography>
      {subHeader && (
        <Typography sx={{ color: colors.darkGray, marginBottom: '16px' }}>
          {subHeader}
        </Typography>
      )}
    </Box>
    <TableContainer sx={{ marginBottom: '64px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={statusColumnStyle}>
              {LANG.label.documentTable.status}
            </TableCell>
            <TableCell sx={requiredDocumentsColumnStyle}>
              {LANG.label.documentTable.displayName}
            </TableCell>
            <TableCell sx={fileUploadColumnStyle}>
              {LANG.label.documentTable.fileDate}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents &&
            documents
              .filter(
                (document) =>
                  document.properties.status !== 'N/A' &&
                  document.properties.status !== '',
              )
              .map((document) => (
                <TableRow key={document.id}>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'inline-block',
                        width: '12px',
                        height: '12px',
                        backgroundColor: getStatusColor(
                          document.properties.status,
                        ),
                        borderRadius: '50%',
                        margin: '0 16px',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {document.properties.displayName ? (
                      <Box sx={{ display: 'flex', gap: '8px' }}>
                        <Typography>
                          {document.properties.displayName}
                        </Typography>
                        {document.properties.description && (
                          <Tooltip
                            title={
                              <Typography sx={{ fontSize: '14px' }}>
                                {document.properties.description}
                              </Typography>
                            }
                            placement="right"
                          >
                            <InfoOutlined sx={{ color: colors.darkPurple }} />
                          </Tooltip>
                        )}
                      </Box>
                    ) : (
                      LANG.error.displayName
                    )}
                  </TableCell>
                  <TableCell>
                    {canUpload(document.properties.status) ? (
                      <UploadDocument
                        displayName={document.properties.displayName}
                        documentId={document.id}
                      />
                    ) : (
                      <Typography>
                        {document.properties.fileLastModifiedDate &&
                          formatDate(document.properties.fileLastModifiedDate)}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
);
