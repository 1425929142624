import { getDealSummary } from 'api/lambda';
import { generateDealSummaryQueryKey } from './api/queryKeys';

const MINUTES_PER_HOUR = 60;
const MILLISECONDS_PER_MINUTE = 60000;
const CACHE_TIME = 12 * MINUTES_PER_HOUR * MILLISECONDS_PER_MINUTE;
const STALE_TIME = 12 * MINUTES_PER_HOUR * MILLISECONDS_PER_MINUTE;

export const dealSummaryQuery = (
  dealId: string,
  associatedCompanyId: string | null,
) => ({
  queryKey: generateDealSummaryQueryKey(dealId),
  queryFn: () => getDealSummary(dealId, associatedCompanyId),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => console.error(error),
  retry: 1,
});
