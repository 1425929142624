/* eslint-disable no-nested-ternary */
import { Box, CircularProgress, Typography } from '@mui/material';
import { ERCADocumentTable } from 'components/ERCADocumentTable';
import { PortalHeader } from 'components/PortalHeader';
import { dealSummaryQuery } from 'queries';
import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { colors } from 'themes';
import { LANG } from 'components/consts/lang';

export const ERCADocumentUploadPortal: FunctionComponent = () => {
  const { dealId } = useParams() as { dealId: string };
  const [searchParams] = useSearchParams();
  const associatedCompanyId = searchParams.get('associatedCompanyId');

  const {
    data: dealSummary,
    isLoading,
    isError,
  } = useQuery(dealSummaryQuery(dealId, associatedCompanyId));

  return (
    <>
      <PortalHeader
        legalCompanyName={dealSummary?.legalCompanyName}
        showInstructions={!!dealSummary}
        alternativeTitle="ERCA Document Upload"
        alternativeRequiredDocuments={
          <Box id="bullet-points" sx={{ marginBottom: '32px' }}>
            <Typography sx={{ marginLeft: '32px' }}>
              &bull; 2022 Profit and Loss statements
            </Typography>
            <Typography sx={{ marginLeft: '32px' }}>
              &bull; 2022 Balance Sheets
            </Typography>
            <Typography sx={{ marginLeft: '32px' }}>
              &bull; 3 Bank Statements (from the last 3 months)
            </Typography>
            <Typography sx={{ marginLeft: '32px' }}>
              &bull; 2022 Income Taxes
            </Typography>
            <Typography sx={{ marginLeft: '32px' }}>
              &bull; A copy of a debt schedule
            </Typography>
          </Box>
        }
        alternativeInstructions="Congrats on taking the first step to claim the Employee Retention
        Credit Advance! Please upload the required documents below. Once all of
        your documents are confirmed, our lenders will begin the advance process."
      />

      {isLoading ? (
        <Box
          sx={{
            width: '75%',
            height: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : dealSummary === undefined ||
        dealSummary.ercaDocuments === undefined ||
        isError ||
        dealSummary.dealType !== 'ERCA' ? (
        <Typography sx={{ color: colors.lightGray3 }}>
          Please contact your assigned representative for a valid URL
        </Typography>
      ) : (
        <ERCADocumentTable
          title="Upload ERCA Documents"
          subHeader={LANG.description.documentTable.default}
          documents={dealSummary.ercaDocuments}
        />
      )}
    </>
  );
};
