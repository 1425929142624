/* eslint-disable no-nested-ternary */
import { Box, CircularProgress, Typography } from '@mui/material';
import { DocumentTable } from 'components/DocumentTable';
import { PortalHeader } from 'components/PortalHeader';
import { dealSummaryQuery } from 'queries';
import { FunctionComponent, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { colors } from 'themes';
import { CheckUpload } from 'components/CheckUpload';
import { LANG } from '../components/consts/lang';
import { UploadDocument } from '../components/UploadDocument';

export const DocumentUploadPortal: FunctionComponent = () => {
  const { dealId } = useParams() as { dealId: string };
  const [searchParams] = useSearchParams();
  const associatedCompanyId = searchParams.get('associatedCompanyId');
  const [currentTab, setCurrentTab] = useState<
    'Refund Application' | 'IRS Checks'
  >('Refund Application');

  const {
    data: dealSummary,
    isLoading,
    isError,
  } = useQuery(dealSummaryQuery(dealId, associatedCompanyId));

  useEffect(() => {
    // 3090816='Invoice Signed', 10451316='Awaiting Refund'
    if (
      dealSummary?.dealStage === '3090816' ||
      dealSummary?.dealStage === '10451316'
    ) {
      setCurrentTab('IRS Checks');
    }
  }, [dealSummary?.dealStage]);

  return (
    <>
      <PortalHeader
        legalCompanyName={dealSummary?.legalCompanyName}
        showInstructions={!!dealSummary}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      {isLoading ? (
        <Box
          sx={{
            width: '75%',
            height: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : dealSummary === undefined ||
        dealSummary.documents === undefined ||
        isError ? (
        <Typography sx={{ color: colors.lightGray3 }}>
          Please contact your assigned representative for a valid URL
        </Typography>
      ) : (
        <>
          {currentTab === 'Refund Application' && (
            <>
              {dealSummary.documents.find((doc) =>
                (doc.properties.displayName ?? '').includes('Most Recent 941'),
              ) && (
                <DocumentTable
                  title="Most Recently Filed 941 with the IRS"
                  subHeader={LANG.description.documentTable.default}
                  documents={dealSummary?.documents.filter((doc) =>
                    (doc.properties.displayName ?? '').includes(
                      'Most Recent 941',
                    ),
                  )}
                />
              )}
              {dealSummary.documents.find((doc) =>
                (doc.properties.displayName ?? '').includes(
                  'Recent bank statement',
                ),
              ) && (
                <DocumentTable
                  title="Recent Bank Statement or Utility Bill"
                  subHeader={LANG.description.documentTable.default}
                  documents={dealSummary?.documents.filter((doc) =>
                    (doc.properties.displayName ?? '').includes(
                      'Recent bank statement',
                    ),
                  )}
                />
              )}
              <DocumentTable
                title="2021 Documents"
                subHeader={LANG.description.documentTable.default}
                documents={dealSummary?.documents.filter(
                  (doc) => doc.properties.year === 2021,
                )}
              />
              <DocumentTable
                title="2020 Documents"
                subHeader={LANG.description.documentTable.default}
                documents={dealSummary?.documents.filter(
                  (doc) => doc.properties.year === 2020,
                )}
              />
              <DocumentTable
                title="2019 Documents"
                subHeader={LANG.description.documentTable.default}
                documents={dealSummary?.documents.filter(
                  (doc) => doc.properties.year === 2019,
                )}
              />
              <DocumentTable
                title="Custom Documents"
                subHeader={
                  <>
                    {LANG.description.documentTable.customTable}
                    <br />
                    {LANG.description.documentTable.default}
                    <UploadDocument showDisplayName />
                  </>
                }
                documents={dealSummary?.documents.filter(
                  (doc) =>
                    ![2019, 2020, 2021, 2023].includes(
                      doc.properties.year ?? NaN,
                    ),
                )}
              />
            </>
          )}
          {currentTab === 'IRS Checks' && (
            <Box>
              <Typography sx={{ color: colors.darkGray, marginBottom: '32px' }}>
                Congratulations on receiving an ERC payment!
              </Typography>
              <Typography sx={{ color: colors.darkGray, marginBottom: '32px' }}>
                {`Please upload a picture of your check below and mark the Tax Period Date and Check Total. \n
              If you need to upload multiple checks, simply refresh the page after submitting your first check. `}
              </Typography>
              <img
                src="check_example.png"
                width="50%"
                style={{ marginBottom: '32px' }}
              />
              <CheckUpload />
            </Box>
          )}
        </>
      )}
    </>
  );
};
