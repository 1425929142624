import { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  OutlinedInput,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { errorHandler } from 'utils/toasts';
import { useQueryClient } from 'react-query';
import { generateDealSummaryQueryKey } from 'api/queryKeys';
import { ACCEPTED_FILE_TYPES } from './consts/consts';
import { LANG } from './consts/lang';
import { uploadDocument } from '../api/lambda';
import { useDocumentUploadMutation } from '../api/mutation';

const wrapperStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'white',
  minHeight: '40px',
};

const showDisplayNameStyle = {
  maxWidth: 'calc(40vw + 87px)',
  padding: '10px',
  border: '1px solid #e5e5e5',
  borderRadius: '5px',
  marginTop: '10px',
  minWidth: '65%',
};

const hideDisplayNameStyle = {
  maxWidth: '400px',
  padding: '0',
  border: 'none',
  borderRadius: '0',
};

const MEGABYTE = 1048576;

interface UploadProps {
  documentId?: string;
  displayName?: string;
  showDisplayName?: boolean;
  alternativeDocumentType?: string;
}

export const UploadDocument: FunctionComponent<UploadProps> = ({
  showDisplayName = false,
  displayName,
  documentId,
  alternativeDocumentType,
}) => {
  const qc = useQueryClient();

  const { dealId } = useParams() as { dealId: string };
  const [displayNameToUpload, setDisplayNameToUpload] = useState('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setDisplayNameToUpload(displayName ?? '');
  }, [displayName]);

  const clearFileInput = () => {
    if (fileRef?.current) fileRef.current.value = '';
  };

  const handleFileSelected = (file: File | undefined) => {
    if (file && file.size > 200 * MEGABYTE) {
      clearFileInput();
      // eslint-disable-next-line no-param-reassign
      file = undefined;
      errorHandler(
        'Selected file is too large, please select a file under 200MB',
      );
    }
    setSelectedFile(file);
  };

  const handleSubmit = async (documentInfo: {
    file: File | undefined;
    documentName: string;
  }) => {
    if (!documentInfo.file) return;

    setSubmitDisabled(true);
    await uploadDocument(
      documentInfo.file,
      documentInfo.documentName,
      dealId,
      documentId,
      alternativeDocumentType,
    );

    qc.invalidateQueries(generateDealSummaryQueryKey(dealId));
  };

  const uploadFileMutation = useDocumentUploadMutation(
    handleSubmit,
    dealId,
    documentId,
  );

  const isInputDisabled = () => uploadFileMutation.isLoading;

  const isSubmitDisabled = () =>
    submitDisabled ||
    isInputDisabled() ||
    (showDisplayName && !displayNameToUpload) ||
    !selectedFile;

  useEffect(() => {
    if (uploadFileMutation.isError) setSubmitDisabled(false);
    if (!uploadFileMutation.isSuccess) return;

    setDisplayNameToUpload('');
    clearFileInput();
    showDisplayName && setSubmitDisabled(false);
  }, [
    uploadFileMutation.isSuccess,
    uploadFileMutation.isError,
    fileRef,
    showDisplayName,
  ]);

  return (
    <Box
      sx={{
        ...wrapperStyle,
        ...(showDisplayName ? showDisplayNameStyle : hideDisplayNameStyle),
      }}
    >
      {showDisplayName && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              paddingRight: 1.5,
              color: 'rgba(0, 0, 0, 0.87);',
              span: {
                color: 'red',
              },
            }}
          >
            {LANG.label.documentName}
            <span>*</span>
          </Typography>
          <OutlinedInput
            type="text"
            sx={{
              height: '40px',
            }}
            onChange={(e) => setDisplayNameToUpload(e.target.value)}
            value={displayNameToUpload}
            disabled={isInputDisabled()}
          />
        </Box>
      )}
      <input
        type="file"
        multiple={false}
        onChange={(event) => handleFileSelected(event.target.files?.[0])}
        accept={ACCEPTED_FILE_TYPES.join(',')}
        disabled={uploadFileMutation.isLoading}
        ref={fileRef}
      />
      {uploadFileMutation.isLoading ? (
        <CircularProgress size={20} sx={{ paddingX: 1 }} />
      ) : (
        <Button
          variant="contained"
          disabled={isSubmitDisabled()}
          onClick={() =>
            uploadFileMutation.mutate({
              file: selectedFile,
              documentName: displayNameToUpload,
            })
          }
        >
          {LANG.button.submit}
        </Button>
      )}
    </Box>
  );
};
