import { AxiosError } from 'axios';
import { toast, Zoom } from 'react-toastify';

export const successHandler = (message: string) => {
  toast.success(message, {
    position: 'top-center',
    autoClose: 5000,
    transition: Zoom,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const errorHandler = (error: unknown) => {
  console.error(error);

  const errorMessage =
    ((error as AxiosError).response?.data as string) ??
    (error as Error).message ??
    (error as Error).toString();

  toast.error(errorMessage, {
    toastId: errorMessage,
    position: 'top-center',
    autoClose: 5000,
    transition: Zoom,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
