/* eslint-disable no-await-in-loop */
import axios, { AxiosError, AxiosResponse } from 'axios';
import { DealSummary, UploadResponse } from 'types/types';

const lambdaAxios = axios.create({
  baseURL: `https://${process.env.REACT_APP_BACKEND_URL}`,
});

const generateHeaders = () => ({
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getDealSummary = async (
  dealId: string | undefined,
  associatedCompanyId: string | null,
) => {
  if (!dealId) return undefined;
  const response = await lambdaAxios.get<DealSummary>(
    `hubspot/deal/summary/${dealId}?associatedCompanyId=${associatedCompanyId}`,
    generateHeaders(),
  );
  return response.data;
};

export const uploadHubspotCheck = async (
  dealId: string | undefined,
  amount: string,
  month: string,
  year: string,
  fileName: string,
) => {
  if (!dealId) return undefined;
  const response = await lambdaAxios.post(
    `hubspot/deal/${dealId}/checks`,
    { amount, month, year, fileName },
    generateHeaders(),
  );
  return response.data;
};

export const uploadDocument = async (
  file: File,
  displayName: string,
  dealId: string,
  documentId?: string,
  alternativeDocumentType?: string,
) => {
  const encodedFilename = encodeURIComponent(file.name);
  let response: AxiosResponse<UploadResponse, unknown>;
  let retryCount = 0;
  let retry = true;
  while (retry) {
    response = await lambdaAxios.put<UploadResponse>(
      `customer-files/${dealId}/${encodedFilename}${
        alternativeDocumentType
          ? `?alternativeDocumentType=${alternativeDocumentType}`
          : ''
      }`,
      undefined,
      generateHeaders(),
    );

    const buffer = await file.arrayBuffer();

    try {
      await axios.put(response.data.putUrl, buffer, {
        headers: { 'Content-Type': file.type },
      });
      retry = false;
    } catch (err) {
      // Too many concurrent uploads to the same Key can cause a 503
      if ((err as AxiosError)?.response?.status === 503) {
        retryCount += 1;
        if (retryCount === 5) {
          throw err;
        }
      } else {
        throw err;
      }
    }
  }

  await lambdaAxios.post(
    `hubspot/document/status?dealId=${dealId ?? ''}&documentId=${
      documentId ?? ''
    }${
      alternativeDocumentType
        ? `&alternativeDocumentType=${alternativeDocumentType}`
        : ''
    }`,
    {
      s3FileName: response!.data.fileName,
      fileNamingConvention: displayName,
    },
    generateHeaders(),
  );
};

export const uploadW9File = async (email: string, file: File) => {
  const encodedFilename = encodeURIComponent(file.name);
  const response = await lambdaAxios.put<UploadResponse>(
    `files/w9/${encodedFilename}`,
    { email },
    generateHeaders(),
  );

  const buffer = await file.arrayBuffer();
  await axios.put(response.data.putUrl, buffer, {
    headers: { 'Content-Type': file.type },
  });

  return file.name;
};
