import { Box, Tab, Tabs, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { colors } from 'themes';
import { UploadInstructions } from './UploadInstructions';

type Tab = 'Refund Application' | 'IRS Checks';

interface IProps {
  legalCompanyName: string | undefined;
  showInstructions?: boolean;
  alternativeTitle?: string;
  alternativeRequiredDocuments?: JSX.Element;
  alternativeInstructions?: string;
  currentTab?: Tab;
  setCurrentTab?: React.Dispatch<React.SetStateAction<Tab>>;
}

// TODO: Remove all these flags and replace with a children prop
// passed in where this is actually used
export const PortalHeader: FunctionComponent<IProps> = ({
  legalCompanyName,
  showInstructions,
  alternativeTitle,
  alternativeRequiredDocuments,
  alternativeInstructions,
  currentTab,
  setCurrentTab,
}) => (
  <>
    <Box
      id="header-bar"
      sx={{
        display: 'flex',
        marginX: '-7.5vw',
        height: '12px',
        maxHeight: '1vw',
        background: colors.darkPurple,
      }}
    />
    <Box id="instructions-section" sx={{ width: '100%' }}>
      <Box
        id="header-text"
        sx={{
          display: 'flex',
          marginTop: '16px',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{ display: 'inline', whiteSpace: 'nowrap' }}
          fontSize="32px"
          fontWeight="700"
          color={colors.mediumGray}
        >
          {alternativeTitle ?? 'Document Upload'}
        </Typography>
        <Box sx={{ display: 'flex', flex: 1 }}>
          {currentTab && setCurrentTab && (
            <Tabs
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              sx={{ marginLeft: '12px' }}
            >
              <Tab
                label="Refund Application"
                value="Refund Application"
                sx={{ fontSize: '22px', fontWeight: '700' }}
              />
              <Tab
                label="IRS Checks"
                value="IRS Checks"
                sx={{ fontSize: '22px', fontWeight: '700' }}
              />
            </Tabs>
          )}
          <Box
            component="img"
            sx={{ marginLeft: 'auto', height: 60 }}
            src="IRLogo.png"
            alt="logo"
          />
        </Box>
      </Box>
      {showInstructions && (
        <Box
          id="legal-company-name"
          sx={{
            marginY: '14px',
          }}
        >
          <Typography sx={{ fontSize: '20px' }}>
            {legalCompanyName ?? 'Company name not available'}
          </Typography>
        </Box>
      )}
      <Box
        id="thin-bar"
        sx={{
          display: 'flex',
          height: '4px',
          background: colors.darkPurple,
          marginBottom: '24px',
        }}
      />
      {showInstructions && <UploadInstructions />}
      {showInstructions && currentTab !== 'IRS Checks' && (
        <>
          <Box
            id="required-documents"
            sx={{ color: colors.darkGray, marginBottom: '32px' }}
          >
            <Typography sx={{ marginBottom: '24px' }}>
              {alternativeInstructions ||
                `Congrats on taking the first step to claim the Employee Retention
              Credit! Please upload the required documents below. Once all of
              your documents are confirmed, our network of tax professionals
              will begin the payroll tax refund claim process.`}
            </Typography>
            <Typography
              sx={{ marginBottom: '24px', textDecoration: 'underline' }}
            >
              Required Documents:
            </Typography>
            {alternativeRequiredDocuments || (
              <Box id="bullet-points" sx={{ marginBottom: '32px' }}>
                <Typography sx={{ marginLeft: '32px' }}>
                  &bull; Quarterly IRS Form 941 (2019, 2020, 2021, and your most
                  recent 941 filed with the IRS)
                </Typography>
                <Typography sx={{ marginLeft: '32px' }}>
                  &bull; Recent bank statement, utility bill, or insurance
                  provider bill/statement (within the last 6 months)
                </Typography>
                <Typography sx={{ marginLeft: '32px' }}>
                  &bull; Quarterly Profit and Loss Statement (2019, 2020, 2021)
                </Typography>
                <Typography sx={{ marginLeft: '32px' }}>
                  &bull; Monthly Payroll Ledger per employee to include: Gross
                  Pay, Hours worked, Deductions/Taxes & Net Pay (2020, 2021)
                </Typography>
                <Typography sx={{ marginLeft: '32px' }}>
                  &bull; Group Healthcare Statement per month (if applicable,
                  2020, 2021)
                </Typography>
                <Typography sx={{ marginLeft: '32px' }}>
                  &bull; Application for Payroll Protection Loan Forgiveness for
                  both 1st and 2nd round (if applicable, 2020, 2021)
                </Typography>
              </Box>
            )}
            <Typography sx={{ marginBottom: '32px' }}>
              For additional assistance reach out to your assigned
              representative.
            </Typography>
          </Box>
          <Box
            id="status-legend"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '32px',
            }}
          >
            <Box
              sx={{
                display: 'inline-block',
                width: '12px',
                height: '12px',
                backgroundColor: '#bb2801',
                borderRadius: '50%',
                margin: '0 4px',
              }}
            />
            <Typography marginRight="8px">Upload Required</Typography>
            <Box
              sx={{
                display: 'inline-block',
                width: '12px',
                height: '12px',
                backgroundColor: '#e6bf2a',
                borderRadius: '50%',
                margin: '0 4px',
              }}
            />
            <Typography marginRight="8px">Pending Review</Typography>
            <Box
              sx={{
                display: 'inline-block',
                width: '12px',
                height: '12px',
                backgroundColor: '#008001',
                borderRadius: '50%',
                margin: '0 4px',
              }}
            />
            <Typography marginRight="8px">Confirmed</Typography>
          </Box>
        </>
      )}
    </Box>
  </>
);
