export const ACCEPTED_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const DOCUMENT_STATUS = {
  NOT_YET_RECIEVED: 'Not Yet Received',
  PENDING_REVIEW: 'Pending IR Review',
  IQ_CONFIRMED: 'IQ Confirmed',
  IR_CONFIRMED: 'Confirmed by IR',
  IQ_QC_CONFIRMED: 'IQ QC Confirmed',
  IR_QC_CONFIRMED: 'QC Confirmed',
};

export const ERCA_DOCUMENT_STATUSES = {
  UPLOAD_REQUIRED: 'Upload Required',
  PENDING_REVIEW: 'Pending Review',
  CONFIRMED: 'Confirmed',
};
