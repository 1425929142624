import {
  Box,
  Button,
  CircularProgress,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { uploadW9File } from 'api/lambda';
import { useW9UploadMutation } from 'api/mutation';
import { UploadInstructions } from 'components/UploadInstructions';
import { FunctionComponent, useRef, useState } from 'react';
import { colors } from 'themes';
import { errorHandler, successHandler } from 'utils/toasts';

const MEGABYTE = 1048576;

export const W9UploadPortal: FunctionComponent = () => {
  const [contactEmail, setContactEmail] = useState('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const fileRef = useRef<HTMLInputElement>(null);

  const clearFileInput = () => {
    if (fileRef?.current) fileRef.current.value = '';
  };

  const handleFileSelected = (file: File | undefined) => {
    if (file && file.size > 200 * MEGABYTE) {
      clearFileInput();
      setSelectedFile(undefined);
      errorHandler(
        'Selected file is too large, please select a file under 200MB',
      );
      return;
    }
    setSelectedFile(file);
  };

  const handleSubmit = async (w9Info: {
    email: string;
    file: File | undefined;
  }) => {
    const { email, file } = w9Info;
    if (!email) {
      errorHandler('Please enter an email address');
      return;
    }
    if (!file) {
      errorHandler('Please select a file to upload');
      return;
    }
    await uploadW9File(email, file);
    successHandler('File uploaded successfully');
    setContactEmail('');
    setSelectedFile(undefined);
    clearFileInput();
  };

  const uploadW9Mutation = useW9UploadMutation(handleSubmit);

  const downloadW9 = () => {
    const a = document.createElement('a');
    const url = 'forms/fw9.pdf';
    a.href = url;
    a.download = 'Form W9';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Box
        id="header-bar"
        sx={{
          display: 'flex',
          marginX: '-7.5vw',
          height: '12px',
          maxHeight: '1vw',
          background: colors.darkPurple,
        }}
      />

      <Box id="page-body" sx={{ width: '75%' }}>
        <Box
          id="header-text"
          sx={{
            display: 'flex',
            marginTop: '16px',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{ marginRight: '8px' }}
            fontSize="32px"
            fontWeight="700"
            color={colors.darkPurple}
          >
            Refer and Earn Program |
          </Typography>
          <Typography
            sx={{ display: 'inline' }}
            fontSize="32px"
            fontWeight="700"
            color={colors.mediumGray}
          >
            Form W9 Upload
          </Typography>
          <Box
            component="img"
            sx={{ marginLeft: 'auto', height: 60 }}
            src="IRLogo.png"
            alt="logo"
          />
        </Box>
        <Box
          id="thin-bar"
          sx={{
            display: 'flex',
            height: '4px',
            background: colors.darkPurple,
            marginBottom: '24px',
          }}
        />
        <UploadInstructions />

        <Box
          id="instructions-section"
          sx={{ color: colors.darkGray, marginBottom: '32px' }}
        >
          <Typography sx={{ marginBottom: '24px' }}>
            Congrats on helping a business claim the Employee Retention Credit!
            Please upload the required{' '}
            <Typography
              sx={{
                display: 'inline',
                color: '#1976d2',
                '&:hover': {
                  textDecoration: 'underline',
                },
                cursor: 'pointer',
              }}
              onClick={downloadW9}
            >
              W-9 form
            </Typography>{' '}
            and your email address below. Once confirmed, we will send you an
            email with your referral bonus.
          </Typography>
          <Button
            sx={{ marginBottom: '32px' }}
            variant="outlined"
            onClick={downloadW9}
          >
            Download W-9 Form
          </Button>
        </Box>
        <Box sx={{ width: '40%' }}>
          <Box
            id="email-input"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '32px',
            }}
          >
            <Typography
              sx={{ marginBottom: '8px' }}
              fontSize="16px"
              fontWeight="700"
              color={colors.darkPurple}
            >
              Please enter the email address that we emailed to request this
              upload
            </Typography>
            <OutlinedInput
              type="text"
              style={{
                height: '40px',
                borderRadius: '8px',
              }}
              onChange={(event) => {
                setContactEmail(event.target.value);
              }}
              value={contactEmail}
            />
          </Box>
          <Box
            id="upload-input"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '32px',
            }}
          >
            <Typography
              sx={{ marginBottom: '8px' }}
              fontSize="16px"
              fontWeight="700"
              color={colors.darkPurple}
            >
              Upload W-9
            </Typography>
            <input
              type="file"
              multiple={false}
              onChange={(event) => {
                handleFileSelected(event.target.files?.[0]);
              }}
              accept="application/pdf"
              ref={fileRef}
            />
          </Box>
          {uploadW9Mutation.isLoading ? (
            <CircularProgress size={20} sx={{ paddingX: 1 }} />
          ) : (
            <Button
              variant="contained"
              onClick={() =>
                uploadW9Mutation.mutate({
                  email: contactEmail,
                  file: selectedFile,
                })
              }
            >
              Submit
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};
