/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
import { Box, createTheme, GlobalStyles, ThemeProvider } from '@mui/material';
import { DocumentUploadPortal } from 'pages/DocumentUploadPortal';
import { W9UploadPortal } from 'pages/W9UploadPortal';
import { Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GLOBAL_THEME } from 'themes';
import './App.css';
import { ERCADocumentUploadPortal } from 'pages/ERCADocumentUploadPortal';
import { CriqueGroteskWoff, CriqueGroteskWoff2 } from './assets/fonts';

function App() {
  const queryClient = new QueryClient();

  // VWO Async SmartCode
  useEffect(() => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.id = 'vwoCode';

    script.innerHTML = `window._vwo_code=window._vwo_code || (function() {
      var account_id=708394,
      version = 1.5,
      settings_tolerance=2000,
      library_tolerance=2500,
      use_existing_jquery=false,
      is_spa=1,
      hide_element='body',
      hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
      /* DO NOT EDIT BELOW THIS LINE */
      f=false,d=document,vwoCodeEl=d.querySelector('#vwoCode'),code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},hide_element_style:function(){return'{'+hide_element_style+'}'},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},getVersion:function(){return version},getMatchedCookies:function(e){var t=[];if(document.cookie){t=document.cookie.match(e)||[]}return t},getCombinationCookie:function(){var e=code.getMatchedCookies(/(?:^|;)\s?(_vis_opt_exp_\d+_combi=[^;$]*)/gi);e=e.map(function(e){try{var t=decodeURIComponent(e);if(!/_vis_opt_exp_\d+_combi=(?:\d+,?)+\s*$/.test(t)){return''}return t}catch(e){return''}});var i=[];e.forEach(function(e){var t=e.match(/([\d,]+)/g);t&&i.push(t.join('-'))});return i.join('|')},init:function(){if(d.URL.indexOf('__vwo_disable__')>-1)return;window.settings_timer=setTimeout(function(){_vwo_code.finish()},settings_tolerance);var e=d.createElement('style'),t=hide_element?hide_element+'{'+hide_element_style+'}':'',i=d.getElementsByTagName('head')[0];e.setAttribute('id','_vis_opt_path_hides');vwoCodeEl&&e.setAttribute('nonce',vwoCodeEl.nonce);e.setAttribute('type','text/css');if(e.styleSheet)e.styleSheet.cssText=t;else e.appendChild(d.createTextNode(t));i.appendChild(e);var n=this.getCombinationCookie();this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version+(n?'&c='+n:''));return settings_timer}};window._vwo_settings_timer = code.init();return code;}());`;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles
        styles={{
          '@font-face': {
            fontFamily: 'Crique Grotesk',
            src:
              `url(${CriqueGroteskWoff2}) format("woff2"),` +
              `url(${CriqueGroteskWoff}) format("woff")`,
          },
          body: {
            margin: 0,
            backgroundColor: '#F5F5F5',
          },
        }}
      />
      <ThemeProvider theme={createTheme(GLOBAL_THEME)}>
        <Box
          sx={{
            paddingX: '7.5vw',
          }}
        >
          <BrowserRouter>
            <Suspense fallback={<div />}>
              <Routes>
                <Route path="/" element={<DocumentUploadPortal />} />
                <Route path=":dealId" element={<DocumentUploadPortal />} />
                <Route path="/w9upload" element={<W9UploadPortal />} />
                <Route path="/erca" element={<ERCADocumentUploadPortal />} />
                <Route
                  path="/erca/:dealId"
                  element={<ERCADocumentUploadPortal />}
                />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </Box>
        <ToastContainer />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
