import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { colors } from 'themes';

export const UploadInstructions: FunctionComponent = () => (
  <Box
    id="upload-instructions"
    sx={{
      color: colors.darkPurple,
      backgroundColor: colors.lightBlueGray,
      borderRadius: '8px',
      padding: '16px',
      marginBottom: '24px',
    }}
  >
    <Typography>Uploadable file types (must be 50mb or less):</Typography>
    <Typography>PNG, JPG, JPEG, PDF, DOC, DOCX, CSV, XLS, XLSX</Typography>
  </Box>
);
