import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { errorHandler, successHandler } from 'utils/toasts';
import { useRef, useState } from 'react';
import { useDocumentUploadMutation } from 'api/mutation';
import { useParams } from 'react-router-dom';
import { uploadHubspotCheck, uploadDocument } from 'api/lambda';
import { generateDealSummaryQueryKey } from 'api/queryKeys';
import { useQueryClient } from 'react-query';
import { LANG } from './consts/lang';
import { ACCEPTED_FILE_TYPES } from './consts/consts';

const sharedContainerStyle = {
  padding: 2,
  boxSizing: 'border-box',
};

const statusColumnStyle = {
  maxWidth: '20%',
  minWidth: '20%',
  ...sharedContainerStyle,
};

const requiredDocumentsColumnStyle = {
  maxWidth: '35%',
  minWidth: '35%',
  ...sharedContainerStyle,
};

const fileUploadColumnStyle = {
  maxWidth: '30%',
  minWidth: '30%',
  ...sharedContainerStyle,
};

const MEGABYTE = 1048576;

export const CheckUpload = () => {
  const { dealId } = useParams() as { dealId: string };
  const qc = useQueryClient();
  const [formattedAmount, setFormattedAmount] = useState<string>('');

  const formSchema = yup.object().shape({
    month: yup.string().required(),
    year: yup.string().required(),
    amount: yup.string().required('Amount is required'),
  });

  const formOptions = {
    resolver: yupResolver(formSchema),
  };

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  const fileRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File>();

  const clearFileInput = () => {
    if (fileRef?.current) fileRef.current.value = '';
  };

  const handleUploadFile = async (documentInfo: {
    file: File | undefined;
    documentName: string;
  }) => {
    if (!documentInfo.file) return;

    await uploadDocument(
      documentInfo.file,
      documentInfo.documentName,
      dealId,
      undefined,
      'checks',
    );

    qc.invalidateQueries(generateDealSummaryQueryKey(dealId));
  };

  const uploadFileMutation = useDocumentUploadMutation(
    handleUploadFile,
    dealId,
  );

  const handleFileSelected = (file: File | undefined) => {
    if (file && file.size > 200 * MEGABYTE) {
      clearFileInput();
      // eslint-disable-next-line no-param-reassign
      file = undefined;
      errorHandler(
        'Selected file is too large, please select a file under 200MB',
      );
    }
    if (file && !ACCEPTED_FILE_TYPES.includes(file.type)) {
      clearFileInput();
      // eslint-disable-next-line no-param-reassign
      file = undefined;
      errorHandler(
        'Selected file is not the correct type. Please select a' +
          ' PNG, JPG, JPEG, PDF, DOC, DOCX, CSV, XLS, or XLSX file.',
      );
    }
    setSelectedFile(file);
  };

  // Disable submit if file is not uploaded
  const isSubmitDisabled = () =>
    uploadFileMutation.isSuccess ||
    uploadFileMutation.isLoading ||
    !selectedFile;

  const onSubmit = async (data: {
    month: string;
    year: string;
    amount: string;
  }) => {
    if (!selectedFile) return;
    const renamedFile = new File(
      [selectedFile],
      `${data.month}-${data.year}-check.${selectedFile.name
        .split('.')
        .slice(-1)}`,
      { type: selectedFile.type },
    );

    await uploadFileMutation.mutate({
      file: renamedFile,
      documentName: `${data.month}-${data.year}-check`,
    });

    const month = data.month.toString().padStart(2, '0');

    await uploadHubspotCheck(
      dealId,
      data.amount,
      month,
      data.year.toString(),
      renamedFile.name,
    );

    successHandler('Uploaded check successfully!');
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Remove any non-numeric characters except the decimal point
    const numericValue = event.target.value.replace(/[^0-9.]/g, '');

    // Check if there's more than one decimal point
    const parts = numericValue.split('.');
    if (parts.length > 2) return;

    // Enforce only 2 decimals
    if (parts[1] && parts[1].length > 2) return;

    const dollarPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // If there's a decimal point, include it even if there's no cent part yet
    const centPart = parts[1] !== undefined ? `.${parts[1]}` : '';
    const formattedInputValue = `$${dollarPart}${centPart}`;

    setFormattedAmount(formattedInputValue);

    // Update the form state with the value that has no formatting
    setValue('amount', numericValue, { shouldValidate: true });
  };

  const monthOptions = ['03', '06', '09', '12'];

  const yearOptions = ['2020', '2021'];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TableContainer sx={{ marginBottom: '64px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={requiredDocumentsColumnStyle}>
                {LANG.label.checkUpload.amount}
              </TableCell>
              <TableCell sx={fileUploadColumnStyle}>
                {LANG.label.checkUpload.periodDate}
              </TableCell>
              <TableCell sx={statusColumnStyle}>
                {LANG.label.checkUpload.checkUpload}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <TextField
                  variant="standard"
                  value={formattedAmount}
                  onChange={handleAmountChange}
                  error={!!errors.amount}
                  helperText={errors.amount?.message}
                />
              </TableCell>
              <TableCell>
                <Select
                  placeholder="mm"
                  variant="standard"
                  inputProps={{
                    maxLength: 2,
                  }}
                  {...register('month')}
                >
                  {monthOptions.map((month) => (
                    <MenuItem value={month}>{month}</MenuItem>
                  ))}
                </Select>{' '}
                /{' '}
                <Select
                  placeholder="yyyy"
                  variant="standard"
                  type="text"
                  {...register('year')}
                >
                  {yearOptions.map((year) => (
                    <MenuItem value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell sx={{ display: 'flex', flexDirection: 'row' }}>
                <input
                  type="file"
                  multiple={false}
                  onChange={(event) =>
                    handleFileSelected(event.target.files?.[0])
                  }
                  accept={ACCEPTED_FILE_TYPES.join(',')}
                  disabled={uploadFileMutation.isLoading}
                  ref={fileRef}
                />
                <button type="submit" disabled={isSubmitDisabled()}>
                  Submit
                </button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </form>
  );
};
